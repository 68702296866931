// import { Link } from "react-router-dom";
// import { Navigate, Route, Routes, useLocation } from "react-router";
// import { KanbanScreen } from "screens/kanban";
// // import { EpicScreen } from "screens/epic";
// import styled from "@emotion/styled";
// import { Menu } from "antd";

// //获取url当前是kanban还是epic
// const useRouteType = () => {
//   const units = useLocation().pathname.split("/");
//   return units[units.length - 1];
// };

// export const ProjectScreen = () => {
//   const routeType = useRouteType();
//   return (
//     <Container>
//       <Aside>
//         <Menu mode={"inline"} selectedKeys={[routeType]}>
//           <Menu.Item key={"kanban"}>
//             <Link to={"kanban"}>看板</Link>
//           </Menu.Item>
//           <Menu.Item key={"epic"}>
//             <Link to={"epic"}>任务组</Link>
//           </Menu.Item>
//         </Menu>
//       </Aside>
//       <Main>
//         <Routes>
//           {/*projects/:projectId/kanban*/}
//           <Route path={"/kanban"} element={<KanbanScreen />} />
//           {/*projects/:projectId/epic*/}
//           {/* <Route path={"/epic"} element={<EpicScreen />} /> */}
//           <Route
//             path="/"
//             element={
//               <Navigate
//                 to={window.location.pathname + "/kanban"}
//                 replace={true}
//               />
//             }
//           />
//         </Routes>
//       </Main>
//     </Container>
//   );
// };

// const Aside = styled.aside`
//   background-color: rgb(244, 245, 247);
//   display: flex;
// `;

// const Main = styled.div`
//   box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.1);
//   display: flex;
//   overflow: hidden;
// `;

// const Container = styled.div`
//   display: grid;
//   grid-template-columns: 16rem 1fr;
// `;

import styled from "@emotion/styled";
import { Menu } from "antd";
import { Link, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { EpicScreen } from "screens/epic";
import { KanbanScreen } from "screens/kanban";

const useRouteType = () => {
  const units = useLocation().pathname.split("/");
  return units[units.length - 1];
};

export const ProjectScreen = () => {
  const routeType = useRouteType();

  return (
    <Container>
      <Aside>
        <Menu mode="inline" selectedKeys={[routeType]}>
          <Menu.Item key="kanban">
            <Link to="kanban">看板</Link>
          </Menu.Item>
          <Menu.Item key="epic">
            <Link to="epic">任务组</Link>
          </Menu.Item>
        </Menu>
      </Aside>
      <Main>
        <Routes>
          <Route path="kanban" element={<KanbanScreen />} />
          <Route path="epic" element={<EpicScreen />} />
          {/* 重定向，子路由下 / 路径重定向到 /kanban */}
          <Route
            path="/"
            element={
              <Navigate
                to={window.location.pathname + "/kanban"}
                replace={true}
              />
            }
          />
        </Routes>
      </Main>
    </Container>
  );
};

const Aside = styled.aside`
  background-color: rgb(244, 245, 247);
  display: flex;
`;

const Main = styled.div`
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 16rem 1fr;
  width: 100%;
`;
